import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { MousePointerClick, YoutubeIcon } from 'lucide-react';
import { MicVocal } from 'lucide-react';
import { MonitorUp } from 'lucide-react';
import { CloudDownload } from 'lucide-react';
import { CalendarCheck } from 'lucide-react';
import { Lightbulb } from 'lucide-react';
import { SwatchBook } from 'lucide-react';



const features = [
  {
    title: "Instant Video Generation",
    description:
      "Share your story or choose from existing stories and our AI transforms it into an engaging short video with perfect pacing and transitions",
    image: <MousePointerClick className="mb-4 stroke-purple-800 size-10"/>,
  },
  {
    title: "Shorts creation on Auto-pilot",
    description:
      "Using Schedule feature, put your Shorts creation hustle on auto-pilot mode. Let AI take care of creating the content, till posting in Youtube",
    image: <CalendarCheck className="  mb-4 stroke-purple-800 size-10"/>,
  },
  {
    title: "Youtube Integration",
    description:
      "With one click, post the short in your Youtube account",
    image: <MonitorUp className="  mb-4 stroke-purple-800 size-10"/>,
  },
  {
    title: "Shorts Download",
    description:
      "Download the created Shorts at any time from cloud.",
    image: <CloudDownload className="  mb-4 stroke-purple-800 size-10"/>,
  },
  {
    title: "10 crafted themes",
    description:
      "If you are out of ideas, no issues! Let AI create content for you under 10 different themes.",
    image: <SwatchBook className="  mb-4 stroke-purple-800 size-10"/>,
  },
  {
    title: "Voice Narration",
    description:
      "Choose from multiple AI voices for perfect narration every time",
    image: <MicVocal className="  mb-4 stroke-purple-800 size-10"/>,
  }

];



export const Features = () => {
  return (
    <section
      id="features"
      className="container sm:py-4 space-y-2"
    >
      <h4 className="text-3xl lg:text-4xl font-bold text-center self-center mb-2">
        Powerful AI features that make content creation effortless
      </h4>

      {/* <div className="flex flex-wrap md:justify-center gap-4">
        {featureList.map((feature) => (
          <div key={feature}>
            <Badge
              variant="secondary"
              className="text-sm"
            >
              {feature}
            </Badge>
          </div>
        ))}
      </div> */}

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 ">
        {features.map(({ title, description, image }) => (
          <Card key={title} className="border-purple-200">
            <CardHeader>
            
              <CardTitle>
                <span>
                {image}  {title} 
                </span>
              
                </CardTitle>
            </CardHeader>

            <CardContent>{description}</CardContent>

           
          </Card>
        ))}
      </div>
    </section>
  );
};
