// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect, use } from 'react'
import { useToast } from "@/components/ui/use-toast"
import { Separator } from "@/components/ui/separator"
import { AudioPlayer } from 'react-audio-play';
import './createvideo.css'
import { Toaster } from "@/components/ui/toaster"
import { PulseLoader } from "react-spinners";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
// import { RocketIcon } from "@radix-ui/react-icons"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import Confetti from 'react-confetti'


import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"

import {
  ChevronLeft,
  Home,
  LineChart,
  RefreshCcwDot,
  Clapperboard,
  BadgeInfo,
  Package,
  Package2,
  PanelLeft,
  PlusCircle,
  Search,
  Settings,
  RocketIcon,
  ShoppingCart,
  Upload,
  Users2,
} from "lucide-react"

import { Skeleton } from "@/components/ui/skeleton"
import { Badge } from "@/components/ui/badge"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Textarea } from "@/components/ui/textarea"
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle-group"
import { Toggle } from "@/components/ui/toggle"

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import DashboardLayout from "@/custom_component/dashboardLayout"
import { auth } from "./../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
const Profile = () => {
  const [content, setContent] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [userplan, setUserplan] = useState({});
  const [themes, setThemes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callingvideo, setCallingvideo] = useState(false);
  const [connectedAccount, setconnectedAccount] = useState([]);

  const [contentRequestID, setContentRequestID] = useState({});
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const [imageStyle, setImageStyle] = useState('pop_art');
  const [audio, setAudio] = useState('alloy');
  const { toast } = useToast()
  let navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        user.getIdToken().then(function (idToken) {  // <------ Check this line
          setIdToken(idToken);

        });
        // ...
      } else {
        navigate("/")
      }
    });
    // getThemes();
  }, [])


  useEffect(() => {
    if (!idToken) return;
    getUserPlan();
  }, [idToken]);

    useEffect(() => {
    if (!idToken) return;
    getConnectedAccounts();
  }, [idToken]);

  function goToDashboard() {
    navigate("/dashboard");
  }

  const getUserPlan = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_user_plan`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken,
        },
      });

      if (response.ok) {
        const { message: message, success: success, error_code: error_code, data: data } = await response.json();
        setUserplan(data);
      } else if (response.status === 404 || response.status === 401) {
        navigate("/");
        toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({ title: "Something went wrong", description: "Please try again" });
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Fetch error:", error);
      }
    }
  };

  const getConnectedAccounts = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_connected_yt_accounts`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken,
        },
      });

      if (response.ok) {
        const { message: message, success: success, error_code: error_code, data: data } = await response.json();
        if (error_code == 200) {
          setconnectedAccount(data)
        }

      } else if (response.status === 404 || response.status === 401) {
        navigate("/");
        toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({ title: "Something went wrong", description: "Please try again" });
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Fetch error:", error);
      }
    }
  };


  async function logOut() {
    console.log("Logging out");
    signOut(auth).then(() => {
      console.log("Signed out");
      navigate('/home')
    }).catch((error) => {
      console.log("Signed out failed");
    })

  }

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40 sm:flex">
      <DashboardLayout />
      <main className=" flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 ">
        <div className="mx-auto  max-w-[59rem] sm:mx-auto flex-1 auto-rows-max gap-4 pt-3.5">
          <div className="flex items-center gap-4">
            <Button variant="outline" size="icon" className="h-7 w-7" onClick={goToDashboard}>
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back</span>
            </Button>
            <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
              Profile
            </h1>

          </div>
          <div className=" gap-4 md:grid-cols-[1fr_250px] pt-4">
            <div className=" auto-rows-max items-start">
              {idToken ?
                <Card className="w-[350px]">
                  <CardHeader>
                    <CardTitle className=" text-lg">Your Youtube Accounts</CardTitle>
                    <CardDescription>Connect multiple channels and start posting videos with a single click.</CardDescription>
                  </CardHeader>
                  <CardContent>
                      <div className="grid w-full items-center gap-4">
                        <div className="flex flex-col space-y-1.5">
                        {connectedAccount.length > 0 ? <Label htmlFor="name">Connected Accounts:</Label> : ''}
                          {connectedAccount.length > 0 ?
                            connectedAccount.map(acc => {
                              return <Badge variant="outline" className=" w-fit">{acc}</Badge>
                            })
                            : 'No channels connected'}
                        </div>

                      </div>
                  </CardContent>
                  <CardFooter className="flex justify-between">
                  <form action={`${import.meta.env.VITE_ENV}/content/authorize/${idToken}`} method="GET">
                            <Button type="submit" > Connect a Youtube Account</Button>
                          </form>
                  </CardFooter>
                </Card>

                : ''
              }
              {idToken ? 
              <div className=" pt-6">
                <Card className="w-[350px]">
                  <CardHeader>
                    <CardTitle className=" text-lg">Upgrade your plan</CardTitle>
                    <CardDescription>Upgrade to get more credits and create more videos.</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div>
                    <Label className=" text-base"> Current Plan: </Label>
                    <span className=" text-blue-950">{userplan['plan_name']}</span>
                    </div>
                    <div>
                     <Label className=" text-base"> Credits Available: </Label>
                    <span>{userplan['videos_left_month']}</span>
                    </div>
                    
                  </CardContent>
                  <CardFooter>
                  <div>
                  <Button type="submit" className=" bg-purple-800 text-base">
                  <a href="https://getaishorts.lemonsqueezy.com/buy/a5ebc448-5dbd-4e9b-a3c3-b1e3dfeecb07"  target="_blank" > Upgrade Plan Now</a>
                  </Button>
                  <div>
                  <Label className=" text-sm"> *1 credit = 1 shorts </Label>

                  </div>
                  
                  
                  </div>
                  </CardFooter>
                 
                </Card>
</div>
                : ''
              }
            </div>
          </div>
        </div>
      </main>
      <Toaster />

    </div>
  )
}
export default Profile;