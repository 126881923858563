import { Button } from "@/components/ui/button";
import { Outlet, Link, useNavigate } from "react-router-dom";

export const Cta = () => {
  let navigate = useNavigate();

  function goToLogin(){
    navigate("/login");
  }


  return (
    <section
      id="cta"
      className="bg-muted/50 pb-16 mb-24 sm:mb-32"
    >
      <div className="container lg:grid lg:grid-cols-2 place-items-center">
        <div className="lg:col-start-1">
          <h2 className="text-3xl md:text-4xl font-bold ">
          Not Ready 
            <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
              {" "}
              or a Full Plan?{" "}
            </span>
            No Problem!
          </h2>
          <p className=" text-xl mt-4 mb-8 lg:mb-0">
          Get started by buying credits even for just $1. No commitment, no hassle.
          <div className=" text-purple-800 font-bold"> * $1 = 1 Credit = 1 Video</div>
          </p>
        </div>

        <div className="space-y-4 lg:col-start-2">
          <Button onClick={goToLogin} className="w-full md:mr-4 md:w-auto text-base bg-purple-700">Buy Credits Now</Button>
          
        </div>
      </div>
    
    </section>
  );
};
