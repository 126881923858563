import { Button } from "@/components/ui/button";
import { HeroCards } from "./HeroCards";
import { GalleryVerticalEnd } from "lucide-react"
import { Outlet, Link, useNavigate } from "react-router-dom";



export const Hero = () => {

  let navigate = useNavigate();

  function goToLogin(){
    navigate("/login");
  }

  
  return (
    <section className="container grid lg:grid-cols-2 place-items-center py-20 md:py-10 gap-10">
      <div className="text-center lg:text-start space-y-6">
        <main className="text-5xl md:text-6xl font-bold">
          <h1 className="inline">
            <span >
              Transform
            </span >{" "}

            Your {" "} 
            <span className="inline bg-gradient-to-r from-[#F596D3]  to-[#D247BF] text-transparent bg-clip-text">Stories</span>
          </h1>{" "}
          into{" "}
          <h2 className="inline">
            <span className="inline bg-gradient-to-r from-[#61DAFB] via-[#1fc0f1] to-[#03a3d7] text-transparent bg-clip-text">
              Viral Youtube Shorts
            </span>{" "}
            
          </h2>
        </main>

        <p className="text-xl text-muted-foreground md:w-10/12 mx-auto lg:mx-0">
         Use AI to automatically convert your compelling stories into engaging, ready-to-publish 
         Youtube Shorts in minutes
        </p>
        <div className="space-y-4 md:space-y-0 md:space-x-4">
          <Button  onClick={goToLogin} className="w-full md:w-2/3 text-xl bg-purple-700 ">Start Creating Now</Button>

          {/* <a
            rel="noreferrer noopener"
            href="https://github.com/leoMirandaa/shadcn-landing-page.git"
            target="_blank"
            className="w-full md:w-1/3 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
          >
            Github Repository
            <GalleryVerticalEnd className="ml-2 w-5 h-5" />
          </a> */}
        </div>
      </div>

      {/* Hero cards sections */}
      <div className="z-10">
        <HeroCards />
      </div>

      {/* Shadow effect */}
      <div className="shadow"></div>
    </section>
  );
};
