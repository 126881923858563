import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Outlet, Link, useNavigate } from "react-router-dom";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Check } from "lucide-react";



const pricingList= [
  {
    title: "Free",
    popular: 0,
    price: 0,
    description:
    "Perfect for beginners",
    buttonText: "Get Started",
    benefitList: [
      "1 Shorts video",
      "Youtube Integration"
    ]
  },
  {
    title: "Basic",
    popular: 0,
    price: 15,
    description:
      "Perfect for early creators",
    buttonText: "Start Free Trial",
    benefitList: [
      "16 Shorts per month",
      "Youtube Integration",
      "Auto-pilot mode",
      "Video retention in cloud for 30 days"
    ],
  },
  {
    title: "Standard",
    popular: 1,
    price: 25,
    description:
      "Perfect for content creators",
      buttonText: "Start Free Trial",
    benefitList: [
      "28 Shorts per month",
      "Everything from Basic plan",
      "Video retention in cloud for 45 days"
    ],
  },
  {
    title: "Pro",
    popular: 0,
    price: 40,
    description:
      "Perfect for pro creators",
      buttonText: "Start Free Trial",
    benefitList: [
      "45 Shorts per month",
      "Everything from Basic plan",
       "Video retention in cloud for 60 days"
    ],
  },
];

export const Pricing = () => {

  let navigate = useNavigate();

  function goToLogin(){
    navigate("/login");
  }


  return (
    <section
      id="pricing"
      className="container py-24 sm:py-32"
    >
      <h2 className="text-3xl md:text-4xl font-bold text-center">
        Simple
        <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
          {" "}
          Transparent{" "}
        </span>
        Pricing
      </h2>
      <h3 className="text-xl text-center text-muted-foreground pt-4 pb-8">
        Choose the perfect plan for your content creation needs
      </h3>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10">
        {pricingList.map((pricing) => (
          <Card
            key={pricing.title}
            className={
              pricing.popular === 1
                ? "drop-shadow-xl shadow-black/10 dark:shadow-white/10"
                : ""
            }
          >
            <CardHeader>
              <CardTitle className="flex item-center justify-between">
                {pricing.title}
                {pricing.popular === 1 ? (
                  <Badge
                    variant="secondary"
                    className="text-sm text-primary bg-purple-700 text-white"
                  >
                    Most popular
                  </Badge>
                ) : null}
              </CardTitle>
              <div>
                <span className="text-3xl font-bold text-purple-700">${pricing.price}</span>
                <span className="text-muted-foreground"> /month</span>
              </div>

              <CardDescription className>{pricing.description}</CardDescription>
            </CardHeader>

            <CardContent>
              <Button className="w-full" onClick={goToLogin}>{pricing.buttonText}</Button>
            </CardContent>

            <hr className="w-4/5 m-auto mb-4" />

            <CardFooter className="flex">
              <div className="space-y-4">
                {pricing.benefitList.map((benefit) => (
                  <span
                    key={benefit}
                    className="flex"
                  >
                    <Check className="text-green-500" />{" "}
                    <h3 className="ml-2">{benefit}</h3>
                  </span>
                ))}
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    </section>
  );
};
