// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect, use } from 'react'
import { useToast } from "@/components/ui/use-toast"
import { Separator } from "@/components/ui/separator"
import { AudioPlayer } from 'react-audio-play';
import './createvideo.css'
import { Toaster } from "@/components/ui/toaster"
import { PulseLoader } from "react-spinners";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
// import { RocketIcon } from "@radix-ui/react-icons"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import Confetti from 'react-confetti'


import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"

import {
  ChevronLeft,
  Home,
  LineChart,
  RefreshCcwDot,
  Clapperboard,
  BadgeInfo,
  Package,
  Package2,
  PanelLeft,
  PlusCircle,
  Search,
  Settings,
  RocketIcon,
  ShoppingCart,
  Upload,
  Users2,
} from "lucide-react"

import { Skeleton } from "@/components/ui/skeleton"
import { Badge } from "@/components/ui/badge"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Textarea } from "@/components/ui/textarea"
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle-group"
import { Toggle } from "@/components/ui/toggle"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"

import { ChevronsDownUp } from 'lucide-react';


import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import DashboardLayout from "@/custom_component/dashboardLayout"
import { auth } from "./../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
const CreateVideo = () => {
  const [content, setContent] = useState(null);
  const [contentText, setContentText] = useState('');
  const [idToken, setIdToken] = useState(null);
  const [theme, setTheme] = useState(null);
  const [desc, setDesc] = useState('');
  const [tags, setTags] = useState('');
  const [isOpen, setIsOpen] = useState(false)
  const [contentname, setContentname] = useState(null);
  const [themes, setThemes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callingvideo, setCallingvideo] = useState(false);
  const [shortId, setShortId] = useState(false);

  const [contentRequestID, setContentRequestID] = useState({});
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const [imageStyle, setImageStyle] = useState('pop_art');
  const [audio, setAudio] = useState('alloy');
  const { toast } = useToast()
  let navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        user.getIdToken().then(function (idToken) {  // <------ Check this line
          setIdToken(idToken);

        });
        // ...
      } else {
        navigate("/")
      }
    });
    // getThemes();
  }, [])

  useEffect(() => {
    if (!idToken) {
      return;
    }
    getThemes();

  }, [idToken]);

  let imgStyles = [
    {
      "style": "Pop Art",
      "backend_name": "pop_art",
      "sample_img": "/imgstyles/popart.jpg"
    },
    {
      "style": "Photorealistic",
      "backend_name": "photorealistic",
      "sample_img": "/imgstyles/photorealistic.jpg"
    },
    {
      "style": "Anime",
      "backend_name": "anime",
      "sample_img": "/imgstyles/anime.jpg"
    },
    {
      "style": "Cartoon",
      "backend_name": "cartoon",
      "sample_img": "/imgstyles/cartoon.jpg"
    },
    {
      "style": "Painting",
      "backend_name": "painting",
      "sample_img": "/imgstyles/painting.jpg"
    },
    {
      "style": "Sketch",
      "backend_name": "sketch",
      "sample_img": "/imgstyles/sketch.jpg"
    },
    {
      "style": "Watercolor",
      "backend_name": "watercolor",
      "sample_img": "/imgstyles/watercolor.jpg"
    },
    {
      "style": "Vintage",
      "backend_name": "vintage",
      "sample_img": "/imgstyles/vintage.jpg"
    },
    {
      "style": "Fantasy",
      "backend_name": "fantasy",
      "sample_img": "/imgstyles/fantasy.jpg"
    },
    {
      "style": "Cyberpunk",
      "backend_name": "cyberpunk",
      "sample_img": "/imgstyles/cyberpunk.jpg"
    },
    {
      "style": "Minimalist",
      "backend_name": "minimalist",
      "sample_img": "/imgstyles/minimalistic.jpg"
    },
    {
      "style": "Pixel Art",
      "backend_name": "pixel_art",
      "sample_img": "/imgstyles/pixel-art.jpg"
    },
    {
      "style": "Comics",
      "backend_name": "comic_book",
      "sample_img": "/imgstyles/comic.jpg"
    },
    {
      "style": "Steampunk",
      "backend_name": "steampunk",
      "sample_img": "/imgstyles/steampunk.jpg"
    },
    {
      "style": "Gothic",
      "backend_name": "gothic",
      "sample_img": "/imgstyles/gothic.jpg"
    },
    {
      "style": "Surreal",
      "backend_name": "surreal",
      "sample_img": "/imgstyles/surreal.jpg"
    },

    {
      "style": "Graffiti",
      "backend_name": "graffiti",
      "sample_img": "/imgstyles/graffiti.jpg"
    },
    {
      "style": "Baroque",
      "backend_name": "baroque",
      "sample_img": "/imgstyles/baroque.jpg"
    }
  ]

  function goToDashboard() {
    navigate("/dashboard");
  }

  const setAudioType = (audioValue) => {
    setAudio(audioValue)
  }

  const setImagStyle = (imageStyle) => {
    console.log(imageStyle)
    setImageStyle(imageStyle)
  }

  const callThemeContent = async (theme_id) => {
    try {
      setContent(null);
      setLoading(true);
      setTheme(theme_id);
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_content_for_user_theme?theme_id=${theme_id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken
        }
      });

      if (response.status === 200) {
        let contentData = await response.json();

        let success = contentData['success']
        let message = contentData['data']
        setLoading(false);
        if (success == false) {
          if (message.hasOwnProperty("quota_exceeded")) {
            toast({
              variant: "destructive",
              title: "Quota Exceeded",
              description: message['message'],
            })
          } else {
            console.log(message['message'])
            toast({
              variant: "destructive",
              title: "Error in fetching content",
              description: message['message'],
            })
          }
        } else {
          setLoading(false);
          setContent(message)
          setContentText(message['content'])
          setContentname(message['content_title'])
          // setDesc(content['description'])
          // setTags(content['hashtags'])
        }
      } else if (response.status === 404 || response.status == 401) {
        setLoading(false);
        logOut();
        toast({
          title: "Session Closed",
          description: "Facing issue with session. Please try again",
        })
        navigate("/");
      }
      else {
        setLoading(false);
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({
          variant: "destructive",
          title: "Something went wrong",
          description: "Please try again",
        })
      }


    } catch {
      setLoading(false);
      console.log("Gmail Login Failed");
    }

  }

  const refreshContent = async () => {
    try {
      setContent(null);
      setLoading(true);
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_content_for_user_theme?theme_id=${theme}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken
        }
      });

      if (response.status === 200) {
        let contentData = await response.json();

        let success = contentData['success']
        let message = contentData['data']
        setLoading(false);
        if (success == false) {
          if (message.hasOwnProperty("quota_exceeded")) {
            toast({
              variant: "destructive",
              title: "Quota Exceeded",
              description: message['message'],
            })
          } else {
            console.log(message['message'])
            toast({
              variant: "destructive",
              title: "Error in fetching content",
              description: message['message'],
            })
          }
        } else {
          setLoading(false);
          setContent(message)
          setContentText(message['content'])
          setContentname(message['content_title'])
          // setDesc(content['description'])
          // setTags(content['hashtags'])
        }
      } else if (response.status === 404 || response.status == 401) {
        setLoading(false);
        logOut();
        toast({
          title: "Session Closed",
          description: "Facing issue with session. Please try again",
        })
        navigate("/");
      }
      else {
        setLoading(false);
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({
          variant: "destructive",
          title: "Something went wrong",
          description: "Please try again",
        })
      }


    } catch {
      setLoading(false);
      console.log("Gmail Login Failed");
    }

  }
  const getThemes = async () => {
    try {

      const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_themes?byor=1`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken
        }
      });

      if (response.status === 200) {
        let themesData = await response.json();
        let themesDataList = themesData['data'];
        setThemes(themesDataList)

      } else if (response.status === 404 || response.status == 401) {
        logOut();
        toast({
          title: "Session Closed",
          description: "Facing issue with session. Please try again",
        })
        navigate("/");
      }
      else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({
          title: "Something went wrong",
          description: "Please try again",
        })
      }


    } catch {
      console.log("Gmail Login Failed");


    }

  }

  const createVideo = async () => {
    try {
      setCallingvideo(true);
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/save_ods_create_video`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken
        },
        body: JSON.stringify({
          'content': contentText,
          'name': contentname,
          'theme_id': theme,
          'content_id': content['content_id'],
          'voice_type': audio,
          'image_style': imageStyle,
          'video_type': 'Ondemand',
          'description': content['description'],
          'hashtags': content['hashtags'],
          'category_id': content['category_id'],
          'category_name': content['category_name']
          // 'desc': desc,
          // 'tags': tags
        })
      });

      if (response.status === 200) {
        let statusData = await response.json();
        let short_id = statusData['short_id'];
        setShortId(short_id);
      } else if (response.status === 404 || response.status == 401) {
        logOut();
        toast({
          title: "Session Closed",
          description: "Facing issue with session. Please try again",
        })
        navigate("/");
      }
      else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({
          title: "Something went wrong",
          description: "Please try again",
        })
      }


    } catch (err) {
      console.log(err)
      console.log("Gmail Login Failed");


    } finally {
      setCallingvideo(false);
    }

  }

  async function logOut() {
    console.log("Logging out");
    signOut(auth).then(() => {
      console.log("Signed out");
      navigate('/home')
    }).catch((error) => {
      console.log("Signed out failed");
    })

  }

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40 sm:flex">
      {/* <DashboardLayout /> */}
      <main className=" flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 ">
        <div className="mx-auto  max-w-[59rem] sm:mx-auto flex-1 auto-rows-max gap-4 pt-3.5">
          <div className="flex items-center gap-4">
            <Button variant="outline" size="icon" className="h-7 w-7" onClick={goToDashboard}>
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back</span>
            </Button>
            <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
              Create Video
            </h1>
          </div>
          <div className=" gap-4 md:grid-cols-[1fr_250px] pt-4">
            <div className=" auto-rows-max items-start">

              <Card x-chunk="dashboard-07-chunk-2 pt-4  " className=" border-hidden sm:border">

                {shortId ?
                  <div className=" content-center"
                  >                            <Alert className="content-center">
                      <Clapperboard className="h-4 w-4" />
                      <AlertTitle className=' text-xl'>Woohoo!!</AlertTitle>
                      <AlertDescription className='text-xl'>
                        AI is cooking your video.It will take couple of minutes for the video to be available in Dashboard.
                      </AlertDescription>
                    </Alert>
                    <Confetti />
                  </div> :
                  <CardContent>
                    <div className="pt-4 sm:grid-rows-2">
                      <Label htmlFor="category" className=" text-lg">Theme</Label>
                      {/* <Badge  className=" text-lg mb-4">Theme</Badge> */}


                      <Select className=" mt-8 " onValueChange={callThemeContent}>
                        <SelectTrigger
                          id="theme"
                          aria-label="Select a theme"
                        >
                          <SelectValue placeholder="Select a theme" />
                        </SelectTrigger>
                        <SelectContent>
                          {
                            themes.map(theme => {
                              return (
                                <SelectItem value={theme.id}>{theme.name}</SelectItem>
                              )
                            })
                          }
                        </SelectContent>

                      </Select>
                      {
                        content ?
                          <div className="grid gap-2 pt-6 ">
                            {/* <Separator /> */}
                            {/* <Label className=" text-lg pb-4  w-96 sm:w-auto ">Content Details </Label> */}

                            <Label htmlFor="contentname" className=" text-base max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full sm:w-auto ">Shorts Title </Label>
                              <input 
                              className=" max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full pl-2 border "
                              onChange={e => {setContentname(e.target.value)}} value={contentname} maxLength="99" placeholder="Give a title for your shorts video." />
                            <div className=" pt-4">
                            <Label htmlFor="content" className=" text-base max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full sm:w-auto ">Content </Label>

                              <Textarea id={content['content_id']}
                                defaultValue={contentText}
                                onChange={e => {setContentText(e.target.value)}}
                                className="min-h-32 max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full text-base"
                                maxLength="600" placeholder="Enter your content in detail. Max 600 chars allowed."
                              />
                              { theme != '698ldexjZKMeFpITLCIK' ?
                              <i className=" text-xs">You can modify the above content if you want to.</i> : ''
                              }
                            </div>
                            {/* <Label htmlFor="hashtags" className=" text-base max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full sm:w-auto "> Hashtags
                          </Label>
                        <Input
                          defaultValue={content['hashtags']}
                          className="min-h-2 max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full"
                        /> */}
                            <Separator className=" mt-4" />
                            { theme != '698ldexjZKMeFpITLCIK' ?
                            <Button size="sm" className="max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full bg-green-200" variant="secondary" onClick={refreshContent}>
                              <RefreshCcwDot className="pr-2" />
                              Not satisfied? Try something new!
                            </Button> : ""}

                            <div className="pt-2 pb-4 grid grid-cols-subgrid">
                              <Label className=" text-lg pb-4" > Choose Background Voice</Label>
                              <RadioGroup defaultValue="alloy" className=" max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full grid-cols-1 sm:grid-cols-2 gap-4" onValueChange={setAudioType}>
                                <div className=" items-center space-x-2">
                                  <div className="flex justify-center items-center">
                                    <RadioGroupItem value="alloy" id="r1" />
                                    <Label htmlFor="r1" className="pl-2 pr-1">Alloy</Label>
                                    <AudioPlayer

                                      className="custom-style"
                                      src="/audio/alloy.wav"
                                    />
                                  </div>
                                </div>
                                <div className=" items-center space-x-2">
                                  <div className="flex justify-center items-center">
                                    <RadioGroupItem value="echo" id="r2" />
                                    <Label htmlFor="r2" className="pl-2 pr-1">Echo</Label>
                                    <AudioPlayer
                                      className="custom-style"
                                      src="/audio/echo.wav"
                                    />
                                  </div>
                                </div>
                                <div className=" items-center space-x-2">
                                  <div className="flex justify-center items-center">
                                    <RadioGroupItem value="fable" id="r3" />
                                    <Label htmlFor="r3" className="pl-2 pr-1">Fable</Label>
                                    <AudioPlayer
                                      className="custom-style"
                                      src="/audio/fable.wav"
                                    />
                                  </div>
                                </div>
                                <div className=" items-center space-x-2">
                                  <div className="flex justify-center items-center">
                                    <RadioGroupItem value="onyx" id="r3" />
                                    <Label htmlFor="r3" className="pl-2 pr-1">Onyx</Label>
                                    <AudioPlayer
                                      className="custom-style"
                                      src="/audio/onyx.wav"
                                    />
                                  </div>
                                </div>
                                <div className=" items-center space-x-2">
                                  <div className="flex justify-center items-center">
                                    <RadioGroupItem value="nova" id="r3" />
                                    <Label htmlFor="r3" className="pl-2 pr-1">Nova</Label>
                                    <AudioPlayer
                                      className="custom-style"
                                      src="/audio/nova.wav"
                                    />
                                  </div>
                                </div>
                                <div className=" items-center space-x-2">
                                  <div className="flex justify-center items-center">
                                    <RadioGroupItem value="shimmer" id="r3" />
                                    <Label htmlFor="r3" className="pl-2 pr-1">John</Label>

                                    <AudioPlayer
                                      className="custom-style "
                                      src="/audio/shimmer.wav"
                                    />
                                  </div>
                                </div>
                              </RadioGroup>
                              <Separator className=" mt-4" />
                              <div className="pt-4 pb-0 md:pb-3">
                                <Label className=" text-lg pb-4" > Choose Image Style</Label>
                                <div className="max-w-xs md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full h-fit flex items-center justify-center">
                                  <Carousel className="max-w-sm md:max-w-md 2xl:max-w-2xl" >
                                    <CarouselContent className="-ml-1">
                                      {imgStyles.map((value, index) => (
                                        <CarouselItem key={index} className="pl-1 basis-1/1 sm:basis-1/2 border-0">
                                          <div className="p-1">

                                            <Card className="cursor-pointer border-0" onClick={() => setImagStyle(value.backend_name)}>
                                              <CardContent className="flex aspect-square items-center justify-center p-3 md:p-6">
                                                <Toggle size="lg" className=" border-solid h-56 w-52 md:h-full md:w-full hover:bg-violet3 color-mauve11 data-[state=on]:bg-violet6 data-[state=on]:text-violet12 shadow-blackA4 flex  items-center justify-center rounded bg-white text-base leading-4 shadow-[0_2px_10px] focus:shadow-[0_0_0_2px] focus:shadow-black">
                                                  <img src={value.sample_img} className=" max-w-sm md:max-w-md 2xl:max-w-2xl" />
                                                </Toggle>
                                              </CardContent>
                                              <CardFooter className="content-center justify-center ">
                                                <Badge className=" text-lg mb-4 hover:bg-sky-700"> {value.style}</Badge>

                                              </CardFooter>
                                            </Card>

                                          </div>
                                        </CarouselItem>
                                      ))}
                                    </CarouselContent>
                                    <CarouselPrevious />
                                    <CarouselNext />
                                  </Carousel>
                                </div>
                              </div>

                              {/* <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="w-[350px] space-y-2"
    >
      <div className="flex items-center justify-between space-x-4 ">
        <h4 className="text-sm font-semibold">
          Description, tags and more.
        </h4>
        <CollapsibleTrigger asChild>
          <Button variant="ghost" size="sm">
            <ChevronsDownUp  className="h-4 w-4" />
            <span className="sr-only">Toggle</span>
          </Button>
        </CollapsibleTrigger>
      </div>
      
      <CollapsibleContent >
        <div className=" container">
        <Label className=" text-base max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full sm:w-auto ">Video description: </Label>
          <input value={desc} placeholder="Enter a video description" className=" pt-2"></input>
        </div>
        <Separator/>
        <div lassName=" container">
        <Label className=" text-base max-w-72 md:max-w-md xl:max-w-xl lg:max-w-lg 2xl:max-w-full sm:w-auto ">Tags: </Label>
          <input value={tags} placeholder="Enter tags for the video"  className=" pt-2"></input>
          </div>
      </CollapsibleContent>
    </Collapsible> */}

                            </div>
                            <div className="hidden md:grid">
                              <Button size="md" className="pt-2 pb-2" onClick={createVideo}>
                                {callingvideo ? <div>
                                  <PulseLoader color="#ffffff" size={8} />
                                </div> :
                                  <div className=" contents text-base">
                                    <Clapperboard className="pr-2" />
                                    Create Video
                                  </div>
                                }

                              </Button>

                            </div>
                          </div>
                          : loading ?
                            <div className="grid gap-2 pt-8">
                              <div className="flex flex-col space-y-2">
                                <Skeleton className="h-[125px] w-auto rounded-xl" />
                                <div className="space-y-2">
                                  <Skeleton className="h-4 w-[250px]" />
                                  <Skeleton className="h-4 w-[200px]" />
                                </div>
                              </div>
                            </div> : ''
                      }
                      {/* <Select>
                          <SelectTrigger
                            id="content"
                            aria-label="Select content"
                          >
                            <SelectValue placeholder="Select content" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="t-shirts">T-Shirts</SelectItem>
                            <SelectItem value="hoodies">Hoodies</SelectItem>
                            <SelectItem value="sweatshirts">
                              Sweatshirts
                            </SelectItem>
                          </SelectContent>
                        </Select> */}
                      {/* </div> */}
                    </div>
                  </CardContent>
                }
              </Card>
            </div>
            {/* <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
              <Card x-chunk="dashboard-07-chunk-3">
                <CardHeader>
                  <CardTitle>Product Status</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-6">
                    <div className="grid gap-3">
                      <Label htmlFor="status">Status</Label>
                      <Select>
                        <SelectTrigger id="status" aria-label="Select status">
                          <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="draft">Draft</SelectItem>
                          <SelectItem value="published">Active</SelectItem>
                          <SelectItem value="archived">Archived</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card
                className="overflow-hidden" x-chunk="dashboard-07-chunk-4"
              >
                <CardHeader>
                  <CardTitle>Product Images</CardTitle>
                  <CardDescription>
                    Lipsum dolor sit amet, consectetur adipiscing elit
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-2">

                    <div className="grid grid-cols-3 gap-2">
                      <button>

                      </button>
                      <button>

                      </button>
                      <button className="flex aspect-square w-full items-center justify-center rounded-md border border-dashed">
                        <Upload className="h-4 w-4 text-muted-foreground" />
                        <span className="sr-only">Upload</span>
                      </button>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card x-chunk="dashboard-07-chunk-5">
                <CardHeader>
                  <CardTitle>Archive Product</CardTitle>
                  <CardDescription>
                    Lipsum dolor sit amet, consectetur adipiscing elit.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div></div>
                  <Button size="sm" variant="secondary">
                    Archive Product
                  </Button>
                </CardContent>
              </Card>
            </div> */}
          </div>
          {!content ? '' :
            <div className="flex w-auto items-center justify-center  md:hidden pt-2">

              <Button size="sm" className=" pl-12 pr-12" onClick={createVideo}>
                {callingvideo ? <div>
                  <PulseLoader color="#ffffff" size={8} />
                </div> :
                  <div className=" contents text-base">
                    <Clapperboard className="pr-2" />
                    Create Video
                  </div>
                }

              </Button>
            </div>
          }
        </div>
      </main>
      <Toaster />

    </div>
  )
}
export default CreateVideo;