// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect } from 'react'
import { useToast } from "@/components/ui/use-toast"
import VideoThumbnail from 'react-video-thumbnail';
import ReactPlayer from 'react-player';
import { Toaster } from "@/components/ui/toaster"
import { Progress } from "@/components/ui/progress"
import Videos from "./Videos";
import { PulseLoader } from "react-spinners";
import './dashboard.css'
import { TriangleAlert } from 'lucide-react';

import useDownloader from 'react-use-downloader';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  File,
  Home,
  LineChart,
  ListFilter,
  MoreHorizontal,
  Package,
  Package2,
  PanelLeft,
  PlusCircle,
  Search,
  Settings,
  ShoppingCart,
  Users2,
} from "lucide-react"

import { Badge } from "@/components/ui/badge"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import DashboardLayout from "@/custom_component/dashboardLayout";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import { PackagePlus } from 'lucide-react';

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"
import { auth } from "./../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
import moment from "moment";
import UpcomingContent from "./UpcomingContent";

const Dashboard = () => {
  const [content, setContent] = useState('');

  const [idToken, setIdToken] = useState('');
  const [videolist, setVideolist] = useState([]);
  const [contentlist, setContentList] = useState([]);
  const [allPage, setallPage] = useState(true);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [progress, setProgress] = useState(13)
  const [inProgressList, setInProgressList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [contentloading, setContentLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0)
  const [postsPerPage] = useState(10);
  const [disabledNext, setDisabledNext] = useState(false);

  const [disabledPrevious, setDisabledPrevious] = useState(true);

  const [increased, setIncreased] = useState(true);


  const [showVideoSpinner, setShowVideoSpinner] = useState(false);
  const [contentRequestID, setContentRequestID] = useState({});
  const [abortController, setAbortController] = useState(null);
  const { size, elapsed, percentage, download, cancel, error, isInProgress } = useDownloader({
    mode: 'no-cors',
  });
  const { toast } = useToast()
  let navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        user.getIdToken().then(function (idToken) {  // <------ Check this line
          setIdToken(idToken);
        });
        // ...
      } else {
        navigate("/")
      }
    });
  }, [])

  function goToCreateVideo() {
    navigate("/createvideo");
  }

  function onClickNext() {
    const newCurPage = currentPage + 1
    console.log(newCurPage)
    if (videolist.length > currentPage * postsPerPage) {
      // still having items to show from local list
      console.log("here 1")

      setCurrentPage(newCurPage);
    }
    else if (videolist.length <= totalCount) {
      // need to fetch from api
      console.log("here 2")
      setIncreased(true);
      setCurrentPage(newCurPage);
    } else {
      // nothing to show
      console.log("here 3")

      setDisabledNext(true)

    }
  }

  function onClickPrevious() {
    console.log("clicked previsou")
    console.log(currentPage)
    const newCurPage = currentPage - 1
    if (currentPage > 1) {
      console.log(newCurPage)
      setCurrentPage(newCurPage)
      setDisabledNext(false);
    } else {
      console.log("here 4")

      setDisabledPrevious(true);
    }

  }
  function setVisibilePosts() {

    console.log(videolist);
    console.log(currentPage);
    console.log(postsPerPage);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    console.log(indexOfFirstPost);
    console.log(indexOfLastPost);
    const newList = [...videolist];
    console.log(newList.slice(indexOfFirstPost, indexOfLastPost))
    const slicedList = newList.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentPosts(slicedList);
    console.log(slicedList);
  }

  useEffect(() => {
    setVisibilePosts();
  }, [videolist, currentPage]);

  useEffect(() => {
    if (!idToken) return;
    if (!increased) return;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchVideos = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_videos?page=` + currentPage, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'app-token': idToken,
          },
          signal,
        });

        if (response.ok) {
          const { data: videosDataList, total_count: total_count } = await response.json();
          setIncreased(false);
          if (videosDataList.length === 0) {
            setDisabledNext(true);
            setCurrentPage(currentPage - 1)
          } else {
            setTotalCount(total_count);
            const newList = [...videolist];
            newList.push(...videosDataList);
            setVideolist(newList);
            console.log(newList)
            const inProgressItems = videosDataList.filter(item => item.video_status === "Inprogress");
            const idsList = inProgressItems.map(item => item.id);
            setInProgressList(idsList);
          }
        } else if (response.status === 404 || response.status === 401) {
          navigate("/");
          toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
        } else {
          const errorData = await response.json();
          console.error('Error:', errorData.message || response.statusText);
          toast({ title: "Something went wrong", description: "Please try again" });
        }
        setLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error("Fetch error:", error);
        }
        setLoading(false);
      }
    };

    fetchVideos();

    return () => {
      controller.abort();
    };
  }, [idToken, increased]);


  useEffect(() => {
    if (!idToken) return;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchContent = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_upcoming_content`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'app-token': idToken,
          },
          signal,
        });

        if (response.ok) {
          const { data: content_list} = await response.json();
         
          setContentList(content_list);
          console.log(content_list)
          
        } else if (response.status === 404 || response.status === 401) {
          navigate("/");
          toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
        } else {
          const errorData = await response.json();
          console.error('Error:', errorData.message || response.statusText);
          toast({ title: "Something went wrong", description: "Please try again" });
        }
        setLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error("Fetch error:", error);
        }
        setLoading(false);
      }
    };

    fetchContent();

    return () => {
      controller.abort();
    };
  }, [idToken]);

  // Get current posts


  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    if (!idToken) return;

    const controller = new AbortController();
    const signal = controller.signal;


    const interval = setInterval(() => {
      getInprogressVideos();
    }, 60000);

    const getInprogressVideos = async () => {
      if (inProgressList.length == 0) {
        return
      }
      try {
        const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_video_by_ids`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'app-token': idToken,
          },
          body: JSON.stringify({
            'doc_ids': inProgressList
          }),
          signal,
        });

        if (response.ok) {
          const { data: videosDataList } = await response.json();
          // Merge data based on id
          const mergedData = videolist.map(item => {
            const matchingItem = videosDataList.find(d2Item => d2Item.id === item.id);
            return matchingItem ? { ...item, ...matchingItem } : item;
          });

          // Sort merged data by start_time
          const sortedMergedData = mergedData.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
          setVideolist(sortedMergedData);
          const inProgressItems = videosDataList.filter(item => item.video_status === "Inprogress");
          const idsList = inProgressItems.map(item => item.id);
          setInProgressList(idsList);
        } else if (response.status === 404 || response.status === 401) {
          navigate("/");
          toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
        } else {
          const errorData = await response.json();
          console.error('Error:', errorData.message || response.statusText);
          // toast({ title: "Something went wrong", description: "Please try again" });
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error("Fetch error:", error);
        }
      }
    };


    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [idToken, videolist]);

  const handleDownload = (url, fileName) => {
    toast({ title: "Download started", description: "This might take couple of minutes to complete" });

    fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache'
    }).then((response) => response.blob())
      .then((blob) => {

        fileName = fileName + '.mp4'
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        toast({ title: "Error fetching the file", description: "Please try again later" });

        // console.error("Error fetching the file:", error);
      });
  };


  return (
    <div className="flex min-h-screen w-full lg:pl-24 flex-col bg-muted/40">
      <DashboardLayout />
      <div className="flex flex-col gap-48 lg:gap-60 xl:gap-60  2xl:gap-60  sm:py-4 sm:pl-36 pl-1 lg:pl-12 2xl:pl-12">
        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <Tabs defaultValue="all">
            <div className="flex items-center">
              <TabsList>
                <TabsTrigger value="all">All</TabsTrigger>
                <TabsTrigger value="upcoming" className=" sm:flex">Upcoming</TabsTrigger>
              </TabsList>
              <div className="ml-auto flex items-center gap-2">
                {/* <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" size="sm" className="h-8 gap-1">
                      <ListFilter className="h-3.5 w-3.5" />
                      <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">Filter</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem checked>Scheduled shorts</DropdownMenuItem>
                    <DropdownMenuItem>On demand shorts</DropdownMenuItem>
                    <DropdownMenuItem>Archived shorts</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu> */}
                <Button size="sm" onClick={goToCreateVideo} className="h-8 gap-1 bg-purple-700">
                  <PackagePlus className="h-4.5 w-4.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap text-lg">Create Video</span>
                </Button> 
              </div>
            </div>
            <TabsContent value="all">
              <Card x-chunk="dashboard-06-chunk-0">
                <CardHeader>
                  <CardTitle>All Videos</CardTitle>
                  <div className=" content-end">
                    <Pagination className=" hidden md:justify-end md:grid lg:justify-end lg:grid xl:justify-end xl:grid">
                      <PaginationContent>
                        <PaginationItem className=" cursor-pointer" >
                          <PaginationPrevious
                            onClick={onClickPrevious}
                            aria-disabled={disabledPrevious}
                            tabIndex={currentPage <= 1 ? -1 : undefined}
                            className={
                              currentPage <= 1 ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
      
                        <PaginationItem className=" cursor-pointer">
                          <PaginationNext
                            onClick={onClickNext}
                            aria-disabled={disabledNext}
                            className={
                              totalCount <= currentPage * postsPerPage ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </div>
                  <span className=" italic text-sm">* Click the image thumbnail to play the video</span>
                </CardHeader>
                {!loading ? <Videos posts={currentPosts} loading={loading} idToken={idToken} /> : 
                <div className="flex justify-center items-center p-4">Fetching your videos ...</div>}
                
              </Card>
              <div className=" content-end">
                    <Pagination className=" grid md:hidden lg:hidden xl:hidden">
                      <PaginationContent>
                        <PaginationItem className=" cursor-pointer" >
                          <PaginationPrevious
                            onClick={onClickPrevious}
                            aria-disabled={disabledPrevious}
                            tabIndex={currentPage <= 1 ? -1 : undefined}
                            className={
                              currentPage <= 1 ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
                        {/* <PaginationItem>
          <PaginationLink href="#">1</PaginationLink>
        </PaginationItem> */}
                        <PaginationItem className=" cursor-pointer">
                          <PaginationNext
                            onClick={onClickNext}
                            aria-disabled={disabledNext}
                            className={
                              totalCount <= currentPage * postsPerPage ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </div>
            </TabsContent>
            <TabsContent value="upcoming">
              <Card x-chunk="dashboard-06-chunk-0">
                <CardHeader>
                  <CardTitle>Upcoming</CardTitle>
                  
                </CardHeader>
                {!loading ? <UpcomingContent posts={contentlist} loading={loading} idToken={idToken} /> : 
                <div className="flex justify-center items-center p-4">Fetching your content ...</div>}
                
              </Card>
            </TabsContent>
                 
            
                
          
          
          </Tabs>
        </main>
      </div>
      <Toaster />

    </div>
  );
};
export default Dashboard;