import { useState, useEffect } from 'react'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import { Button } from '@/components/ui/button';
import { auth } from "./../firebase-config"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

import { Menu } from "lucide-react";

import { GalleryVerticalEnd } from "lucide-react"


const routeList= [
  {
    href: "#features",
    label: "Features",
  },
  // {
  //   href: "#testimonials",
  //   label: "Testimonials",
  // },
  {
    href: "#pricing",
    label: "Pricing",
  },
  // {
  //   href: "#faq",
  //   label: "FAQ",
  // },
];

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loggedin, setLoggedin] = useState(false)
  let navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        user.getIdToken().then(function(idToken) {  // <------ Check this line
          setLoggedin(true);
       });
        // ...
      } else {
        setLoggedin(false);
      }
    });
  }, [])

  function goToDashboard(){
    navigate("/dashboard");
  }

  return (
    <header className="sticky border-b-[1px] top-0 z-40 w-full bg-white dark:border-b-slate-700 dark:bg-background">
      <NavigationMenu className="mx-auto">
        <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between ">
          <NavigationMenuItem className="font-bold flex">
            <a
              rel="noreferrer noopener"
              href="/"
              className="ml-2 font-bold text-2xl flex"
            >
              <GalleryVerticalEnd className=" mr-2"/>
              AIshorts
            </a>
          </NavigationMenuItem>

          {/* mobile */}
          <span className="flex md:hidden">

            <Sheet
              open={isOpen}
              onOpenChange={setIsOpen}
            >
              <SheetTrigger className="px-2">
                <Menu
                  className="flex md:hidden h-5 w-5"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="sr-only">Menu Icon</span>
                </Menu>
              </SheetTrigger>

              <SheetContent side={"left"}>
                <SheetHeader>
                  <SheetTitle className="font-bold text-xl">
                  AIshorts
                  </SheetTitle>
                </SheetHeader>
                <nav className="flex flex-col justify-center items-center gap-2 mt-4">
                  {routeList.map(({ href, label }) => (
                    <a
                      rel="noreferrer noopener"
                      key={label}
                      href={href}
                      onClick={() => setIsOpen(false)}
                      className="hover:bg-accent hover:text-accent-foreground"
                    >
                      {label}
                    </a>
                  ))}
                  {/* <a
                    rel="noreferrer noopener"
                    href="https://github.com/leoMirandaa/shadcn-landing-page.git"
                    target="_blank"
                    className="w-[110px] border bg-secondary text-secondary-foreground hover:bg-secondary/80"
                  >
                    <GalleryVerticalEnd className="mr-2 w-5 h-5" />
                    AIshorts
                  </a> */}
                    <div>
                    {loggedin? <Button onClick={goToDashboard} className=" m-4">Go to Dashboard</Button>
        : ''}
        </div>
                </nav>
              
       
              </SheetContent>
            </Sheet>
          </span>

          {/* desktop */}
          <nav className="hidden md:flex gap-10">
            {/* {routeList.map((route, i) => (
              <a
                rel="noreferrer noopener"
                href={route.href}
                key={i}
                className="text-[17px] hover:bg-accent hover:text-accent-foreground"
              >
                {route.label}
              </a>
            ))} */}
             {loggedin? <Button onClick={goToDashboard} className=" m-4">Go to Dashboard</Button>
        : ''}
       
          </nav>
         
        </NavigationMenuList>
       
      </NavigationMenu>
    </header>
  );
};
