// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect, use } from 'react'
import { useToast } from "@/components/ui/use-toast"
import { Separator } from "@/components/ui/separator"
import { AudioPlayer } from 'react-audio-play';
import Cron from 'react-cron-generator'
import './cron-builder.css'
import { LoadingButton } from '@/components/ui/loading-button.tsx';

import { Toaster } from "@/components/ui/toaster"
import { PulseLoader } from "react-spinners";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
// import { RocketIcon } from "@radix-ui/react-icons"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import Confetti from 'react-confetti'
import SchedulerList from "./SchedulerList";
import { HEADER } from 'react-cron-generator';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"

import {
  ChevronLeft,
  Home,
  LineChart,
  RefreshCcwDot,
  Clapperboard,
  BadgeInfo,
  Package,
  Package2,
  PanelLeft,
  PlusCircle,
  Search,
  Settings,
  RocketIcon,
  ShoppingCart,
  Upload,
  Users2,
} from "lucide-react"
import TimezoneSelect from "react-timezone-select"

import { Skeleton } from "@/components/ui/skeleton"
import { Badge } from "@/components/ui/badge"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import parser from 'cron-parser';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup
} from "@/components/ui/select"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Textarea } from "@/components/ui/textarea"
import moment from 'moment-timezone';

import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle-group"
import { Toggle } from "@/components/ui/toggle"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import DashboardLayout from "@/custom_component/dashboardLayout"
import { auth } from "../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
import { allTimezones } from "react-timezone-select";

const SchedulerConfig = ({schedulers, setSchedulers, idToken}) => {
  const [themes, setThemes] = useState([]);
  const [imgstyle, setImgstyle] = useState('');
  const [audiotype, setAudiotype] = useState('');
  const [theme, setTheme] = useState({});
  const [imageSize, setImageSize] = useState(" size-16");
  const [ytemailid, setytemailid] = useState('')
  const [schedulerName, setschedulerName] = useState('');
  const { toast } = useToast()
  let navigate = useNavigate();
  const [ytemails, setytemails] = useState([])
  const [utctime, setUtcTime] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )


  let imgStyles = [
    {
      "style": "Random",
      "backend_name": "random",
      "sample_img": "/imgstyles/shuffle.png"
    },
    {
      "style": "Pop Art",
      "backend_name": "pop_art",
      "sample_img": "/imgstyles/popart.jpg"
    },
    {
      "style": "Photorealistic",
      "backend_name": "photorealistic",
      "sample_img": "/imgstyles/photorealistic.jpg"
    },
    {
      "style": "Anime",
      "backend_name": "anime",
      "sample_img": "/imgstyles/anime.jpg"
    },
    {
      "style": "Cartoon",
      "backend_name": "cartoon",
      "sample_img": "/imgstyles/cartoon.jpg"
    },
    {
      "style": "Painting",
      "backend_name": "painting",
      "sample_img": "/imgstyles/painting.jpg"
    },
    {
      "style": "Sketch",
      "backend_name": "sketch",
      "sample_img": "/imgstyles/sketch.jpg"
    },
    {
      "style": "Watercolor",
      "backend_name": "watercolor",
      "sample_img": "/imgstyles/watercolor.jpg"
    },
    {
      "style": "Vintage",
      "backend_name": "vintage",
      "sample_img": "/imgstyles/vintage.jpg"
    },
    {
      "style": "Fantasy",
      "backend_name": "fantasy",
      "sample_img": "/imgstyles/fantasy.jpg"
    },
    {
      "style": "Cyberpunk",
      "backend_name": "cyberpunk",
      "sample_img": "/imgstyles/cyberpunk.jpg"
    },
    {
      "style": "Minimalist",
      "backend_name": "minimalist",
      "sample_img": "/imgstyles/minimalistic.jpg"
    },
    {
      "style": "Pixel Art",
      "backend_name": "pixel_art",
      "sample_img": "/imgstyles/pixel-art.jpg"
    },
    {
      "style": "Comics",
      "backend_name": "comic_book",
      "sample_img": "/imgstyles/comic.jpg"
    },
    {
      "style": "Steampunk",
      "backend_name": "steampunk",
      "sample_img": "/imgstyles/steampunk.jpg"
    },
    {
      "style": "Gothic",
      "backend_name": "gothic",
      "sample_img": "/imgstyles/gothic.jpg"
    },
    {
      "style": "Surreal",
      "backend_name": "surreal",
      "sample_img": "/imgstyles/surreal.jpg"
    },

    {
      "style": "Graffiti",
      "backend_name": "graffiti",
      "sample_img": "/imgstyles/graffiti.jpg"
    },
    {
      "style": "Baroque",
      "backend_name": "baroque",
      "sample_img": "/imgstyles/baroque.jpg"
    }
  ]

  let audioTypes = [
    {
      "screen_name": "Random",
      "name": "random",
      "file": "/imgstyles/shuffle.png"
    },
    {
      "screen_name": "Onyx",
      "name": "onyx",
      "file": "/audio/onyx.wav"
    },
    {
      "screen_name": "Alloy",
      "name": "alloy",
      "file": "/audio/alloy.wav"
    },
    {
      "screen_name": "Echo",
      "name": "echo",
      "file": "/audio/echo.wav"
    },
    {
      "screen_name": "Fable",
      "name": "fable",
      "file": "/audio/fable.wav"
    },
    {
      "screen_name": "Nova",
      "name": "nova",
      "file": "/audio/nova.wav"
    },
    {
      "screen_name": "John",
      "name": "shimmer",
      "file": "/audio/shimmer.wav"
    }
  ]
  const [abortController, setAbortController] = useState(null);

  const [loading, setLoading] = useState(false);
  const [customStyle, setCustomStyle] = useState(true)
  const [open, setOpen] = useState(false)


  useEffect(() => {
    if (!idToken) {
      return;
    }
    getThemes();

  }, [idToken]);


  const options = {
    headers: [HEADER.WEEKLY]
  };

  const [value, setValue] = useState({ value: "0 30 10 * * MON *", text: " At 10:30 AM, only on Monday" })

  const getThemes = async () => {
    try {

      const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_themes`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken
        }
      });

      if (response.status === 200) {
        let themesData = await response.json();
        let themesDataList = themesData['data'];
        setThemes(themesDataList)

      } else if (response.status === 404 || response.status == 401) {
        logOut();
        toast({
          title: "Session Closed",
          description: "Facing issue with session. Please try again",
        })
        navigate("/");
      }
      else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({
          title: "Something went wrong",
          description: "Please try again",
        })
      }


    } catch {
      console.log("Gmail Login Failed");


    }

  }

  function updateCronExpression(cronExpression, utcTime) {
    // Split the cron expression into its components
    let cronParts = cronExpression.split(' ');

    // Extract hours and minutes from the UTC time
    let [utcHours, utcMinutes] = utcTime.split(':');

    // Update the cron parts with the extracted UTC hours and minutes
    cronParts[1] = utcMinutes;
    cronParts[2] = utcHours;

    // Join the updated cron parts back into a single string
    let updatedCronExpression = cronParts.join(' ');

    return updatedCronExpression;
  }

  function getUTCFromLocal(timedetail, timezone) {
    // Extract time from the timedetail string
    const timeMatch = timedetail.match(/\d{1,2}:\d{2}\s[APM]{2}/);

    if (!timeMatch) {
      return 'Invalid input format';
    }

    const time = timeMatch[0];

    // Construct the local time string in the format 'YYYY-MM-DD HH:mm A'
    const now = moment.tz(timezone);
    const localTime = now.format('YYYY-MM-DD') + ` ${time}`;

    // Parse the local time and convert to UTC
    const utcTime = moment.tz(localTime, 'YYYY-MM-DD hh:mm A', timezone).utc().format('HH:mm:ss [UTC]');

    return utcTime;
  }

  function DialogClose() {
    const ope = open;
    setOpen(!ope)
    setTheme({})
    setschedulerName('')
    setValue({ value: "0 30 10 * * MON *", text: " At 10:30 AM, only on Monday" })
    // setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }


  function convertCronToUTC(cronString, timezone) {
    try {
      // Parse the cron expression without considering time zone
      console.log(cronString);
      const interval = parser.parseExpression(cronString);
      console.log(cronString);
      // Get the next occurrence in the specified time zone
      const nextDateInTZ = moment.tz(interval.next().toISOString(), timezone);

      // Convert the next occurrence to UTC
      const nextDateInUTC = nextDateInTZ.clone().utc();

      // Construct the new cron expression in UTC
      const utcCronString = [
        nextDateInUTC.minute(),
        nextDateInUTC.hour(),
        nextDateInUTC.date(),
        nextDateInUTC.month() + 1, // months are 0-based in moment.js
        '*', // Keep the day of the week wildcard
        nextDateInUTC.day() // Day of the week in UTC
      ].join(' ');

      return utcCronString;
    } catch (err) {
      console.error('Error parsing cron string:', err);
      return null;
    }
  }


  function goToDashboard() {
    navigate("/dashboard");
  }

  const createSchedule = () => {
    const days = ["SUN", "FRI", "WED", "MON", "SAT", "THU", "TUE"];
    if ('name' in theme == false) {
      toast({ title: "Theme is missing", description: "Choose a theme" });
    }
    else if (schedulerName == '') {
      toast({ title: "Scheduler name is missing", description: "Give a name to scheduler" });
    }

    else if (!days.some(day => value.value.includes(day))) {
      toast({ title: "Schedule is missing", description: "Choose a schedule" });
    }
    else if (audiotype == '') {
      toast({ title: "Audio Type is missing", description: "Choose a voiceover audio" });
    }
    else if (imgstyle == '') {
      toast({ title: "Image Type is missing", description: "Choose a image style" });
    }
    else {

      createScheduleAPI();

    }
  }

  const createScheduleAPI = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/create_schedule`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken,
        },
        body: JSON.stringify({
          'name': schedulerName,
          'value': value,
          'theme': theme.id,
          'theme_obj': theme,
          'theme_name': theme.name,
          'utc_time': getUTCFromLocal(value.text, selectedTimezone),
          'utc_cron': updateCronExpression(value.value, getUTCFromLocal(value.text, selectedTimezone)),
          "timezone": selectedTimezone,
          "image_style": imgstyle,
          "audio_type": audiotype,
          'yt_email': ytemailid
        })
      });

      if (response.ok) {
        setLoading(false)
        const { data: data, success: success, error_code: error_code, quota_exceeded: quota_exceeded } = await response.json();
        if (!success && error_code == 402) {
          toast({ title: "Mutliple Youtube accounts found. Choose one ", description: "" });
          setytemails(data)
          setytemailid(data[0])
        }
        else if (!success && error_code == 500) {
          toast({ title: "Connect Youtube account from Profile section", description: "Go to Profile and click Connect Youtube to Integrate." });
          DialogClose()

        }
        else if (!success && error_code == 405) {
          toast({ title: "You do not have enough credits to schedule video", description: "Consider upgrading your plan or buy credits" });
          DialogClose()

        }
        if (success == true) {
          toast({ title: "Schedule created", description: "Successfully created the schedule." });
          setSchedulers([data, ...schedulers])
          DialogClose()
          setOpen(false)
        }

      } else if (response.status === 404 || response.status === 401) {
        setLoading(false)
        navigate("/");
        toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
        DialogClose()
      } else {
        setLoading(false)
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({ title: "Something went wrong", description: "Please try again" });
        DialogClose()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.name !== 'AbortError') {
        console.error("Fetch error:", error);
        DialogClose()
      }
    }
  };


  async function logOut() {
    console.log("Logging out");
    signOut(auth).then(() => {
      console.log("Signed out");
      navigate('/home')
    }).catch((error) => {
      console.log("Signed out failed");
    })

  }

  return (
    <div className="flex items-center">

              <div className="ml-auto flex items-center gap-2 ">

                <Dialog open={open} onOpenChange={DialogClose} style={{ zIndex: 20 }} >

                  <DialogContent className="sm:max-w-[425px] p-4 overflow-y-scroll max-h-screen w-11/12  mt-3 mb-3">
                    <DialogHeader>
                      Scheduler Configuration
                    </DialogHeader>
                    <div>
                      {/* <Label className=" pb-8">  </Label> */}
                      <Input placeholder="Scheduler Name"
                        className=" pl-1 pt-4 pb-4 h-6 ring-0 focus:ring-0 "
                        value={schedulerName}
                        onChange={e => setschedulerName(e.currentTarget.value)} />
                    </div>
                    <Separator />

                    <div>
                      <Label className=" pb-8">Schedule </Label>
                      <Cron
                        className=" pt-4"

                        onChange={(e, text) => {
                          setValue({ value: e, text: text });
                        }}
                        value={value.value}
                        showResultText={false}
                        options={options}
                        showResultCron={false}
                      />
                      <Separator />
                      <div className=" pt-1">
                        {/* <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} labelStyle='abbrev' /> */}
                      </div>
                      <div className=" pt-6">

                        <Select className=" mt-8 ml-8 mr-8 pt-8 ring-0 focus:ring-0 border-0 border-none"
                          onValueChange={(e) => setTheme(e)}>
                          <SelectTrigger
                            className="ring-0 focus:ring-0 "
                            id="theme"
                            aria-label="Select a theme"
                          >
                            <SelectValue placeholder="Select a theme" />
                          </SelectTrigger>
                          <SelectContent>
                            {
                              themes.map(theme => {
                                return (
                                  <SelectItem value={theme}>{theme.name}</SelectItem>
                                )
                              })
                            }
                          </SelectContent>

                        </Select>
                      </div>

                      <div className=" pt-6">

                        <Select className=" mt-8 ml-8 mr-8 pt-8 ring-0 focus:ring-0 border-0 border-none"
                          onValueChange={(e) => {
                            setImgstyle(e);
                          }
                          }
                        >
                          <SelectTrigger
                            className="ring-0 focus:ring-0 "
                            id="theme"
                            aria-label="Select a imge style"
                          >
                            <SelectValue placeholder="Select Image style" />
                          </SelectTrigger>
                          <SelectContent>
                            {
                              imgStyles.map(image => {
                                return (
                                  <SelectItem
                                    key={image.backend_name}
                                    value={image.backend_name}
                                  >
                                    <div className="flex items-center">
                                      <div className=" pr-4">
                                        <Avatar >
                                          <AvatarImage src={image.sample_img} />
                                          <AvatarFallback>CN</AvatarFallback>
                                        </Avatar>
                                      </div>
                                      {image.style}
                                    </div>
                                  </SelectItem>
                                  // <SelectItem value={theme}>{theme.name}</SelectItem>
                                )
                              })
                            }
                          </SelectContent>

                        </Select>



                      </div>

                      <div className=" pt-6">

                        <Select className=" mt-8 ml-8 mr-8 pt-8 ring-0 focus:ring-0 border-0 border-none"
                          onValueChange={(e) => setAudiotype(e)}>
                          <SelectTrigger
                            className="ring-0 focus:ring-0 "
                            id="theme"
                            aria-label="Select a Audio format"
                          >
                            <SelectValue placeholder="Select Voice Audio" />
                          </SelectTrigger>
                          <SelectContent>
                            {
                              audioTypes.map(audio => {
                                return (
                                  <SelectItem
                                    key={audio.name}
                                    value={audio.name}
                                  >
                                    <div className="flex items-center">

                                      {audio.screen_name}
                                    </div>
                                  </SelectItem>
                                  // <SelectItem value={theme}>{theme.name}</SelectItem>
                                )
                              })
                            }
                          </SelectContent>

                        </Select>

                        {ytemails.length > 0 ? 
                        <div className=" items-center">
                          <Label htmlFor="yt_email">
                            Select Youtube Account
                          </Label>
                          <Select id="yt_email" className=" w-auto" defaultValue={ytemails[0]} onValueChange={(e) => { setytemailid(e); }}>
                            <SelectTrigger className="w-auto">
                              <SelectValue placeholder="Select a Youtube acount" />
                            </SelectTrigger>
                            <SelectContent>
                              {/* <SelectGroup> */}
                                {ytemails.map(ytemail =>{
                                return (
                                  <SelectItem value={ytemail} active>{ytemail}</SelectItem>
                                )
                              }
                                )}

                              {/* </SelectGroup> */}
                            </SelectContent>
                          </Select>
                        </div> : ''}

                      </div>
                      {'name' in theme ?
                        <div><Badge className=" mt-4 border-0" variant="outline"> {theme.name} videos will auto-post to your YouTube channel {value.text} </Badge></div> : ''}
                    </div>
                    <DialogFooter>
                    {
                        loading ? 
                        <LoadingButton loading>Creating Schedule</LoadingButton>
                        :
                        <Button type="submit" onClick={createSchedule}>Submit</Button>

                      }
                      {/* <Button type="submit" onClick={createSchedule}>Submit</Button> */}
                    </DialogFooter>
                  </DialogContent>
                </Dialog>

                <Button size="sm" className="h-8 gap-1 bg-purple-700" >
                  <PlusCircle className="h-3.5 w-3.5 stroke-white " onClick={() => setOpen(true)} />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap text-lg" onClick={() => setOpen(true)}>Create Scheduler</span>
                </Button>
              </div>
            </div>
  )
}
export default SchedulerConfig;